import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, DataGridPro as MuiDataGridPro } from '@mui/x-data-grid-pro';
import { useUserStorageContext } from '@truescope-web/react/lib/components/UserStorageProvider';
import { loadingStatesLookup } from '@truescope-web/react/lib/components/loading/constants';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { extractError } from '../../../../../components/Api';
import { useConfigContext } from '../../../../../components/Config/ConfigProvider';
import { useApiLookup } from '../../../../../components/providers/ApiLookupProvider';
import { useReportsActivityContext } from '../../ReportsActivityProvider';
import { updateState as reportsActivitiesUpdateState } from '../../reducer';
import ActivitiesTableSelect from '../ActivitiesTableSelect';
import BenchmarkColumnHeader from '../BenchmarkColumnHeader';
import FullDataGridLoader from '../FullDataGridLoader';
import PartialDataGridLoader from '../PartialDataGridLoader';
import { ascendingFirstSortOptions, descendingFirstSortOptions, pageSizeOptions } from '../constants';
import activitiesTableReducer, {
	beginLoadingState,
	getInitialState,
	updatePaginationModel,
	updateSortModel,
	updateState
} from '../reducer';
import { cellValueRenderFunctions, valueRenderFunctions } from '../valueFormatters';
import RecipientActivityTable from './RecipientActivityTable';
import { getRecipientActivities } from './constants';

const RecipientActivitiesTable = () => {
	const [reportsActivityState, reportsActivityDispatch] = useReportsActivityContext();
	const [getClientApi] = useApiLookup();
	const ref = useRef();
	const [{ workspace }] = useConfigContext();
	const [{ dateFrom, dateTo }] = useReportsActivityContext();
	const { userStorage, setUserStorage } = useUserStorageContext();
	const [{ sortModel, nextSortModel, paginationModel, nextPaginationModel, items, loadingState, totalCount, benchmark }, dispatch] =
		useReducer(
			activitiesTableReducer,
			getInitialState({
				defaultSortModel: [{ field: 'emailTo', sort: 'asc' }],
				pageSize: userStorage?.reportsActivity?.recipientsTablePageSize
			})
		);

	const loadData = useCallback(async () => {
		try {
			dispatch(beginLoadingState());
			const {
				items: newItems,
				benchmark: newBenchmark,
				totalCount: newTotalCount
			} = await getRecipientActivities(
				getClientApi,
				workspace.workspace_id,
				dateFrom,
				dateTo,
				nextSortModel?.[0]?.sort === 'desc',
				nextSortModel?.[0]?.field,
				nextPaginationModel.pageSize * nextPaginationModel.page,
				nextPaginationModel.pageSize
			);

			if (arrayIsNullOrEmpty(newItems)) {
				dispatch(updateState({ items: [] }));
				reportsActivityDispatch(reportsActivitiesUpdateState({ isEmpty: true }));
				return;
			}

			const newState = {
				items: newItems,
				totalCount: newTotalCount,
				loadingState: loadingStatesLookup.idle,
				paginationModel: nextPaginationModel,
				sortModel: nextSortModel
			};
			if (nextPaginationModel.page === 0) {
				newState.benchmark = newBenchmark;
			}

			dispatch(updateState(newState));
			reportsActivityDispatch(reportsActivitiesUpdateState({ isEmpty: false }));
		} catch (e) {
			dispatch(updateState({ loadingState: loadingStatesLookup.idle, errorMessage: extractError(e) }));
		}
	}, [getClientApi, workspace?.workspace_id, dateFrom, dateTo, nextSortModel, nextPaginationModel, dispatch, reportsActivityDispatch]);

	useEffect(() => {
		loadData();
	}, [loadData]);

	const columnGroupingModel = useMemo(
		() => [
			{
				groupId: 'Recipient',
				renderHeaderGroup: (params) => <ActivitiesTableSelect {...params} anchorEl={ref.current} />,
				children: [{ field: 'emailTo' }]
			},
			{
				groupId: 'Successful sends',
				children: [{ field: 'deliveryCount' }, { field: 'openCount' }, { field: 'opensPercentage' }]
			},
			{
				groupId: 'Behaviour',
				children: [{ field: 'clickCount' }, { field: 'clicksPercentage' }, { field: 'averageFirstClickSeconds' }]
			},
			{
				groupId: 'Delivery failure',
				children: [{ field: 'bounceCount' }, { field: 'bouncesPercentage' }]
			}
		],
		[ref.current]
	);

	const columns = useMemo(
		() => [
			{
				field: 'emailTo',
				headerName: 'Recipient',
				//flex: 1 - it would be nice to use this, so the column is auto sized. but it crashes the table/app :(
				minWidth: 250,
				disableColumnMenu: true
			},
			{
				field: 'deliveryCount',
				renderHeader: (props) => (
					<BenchmarkColumnHeader {...props} benchmark={benchmark} valueFormatter={valueRenderFunctions.formatNumber} />
				),
				headerName: 'Delivered',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatNumber,
				disableColumnMenu: true,
				sortingOrder: ascendingFirstSortOptions
			},
			{
				field: 'openCount',
				renderHeader: (props) => (
					<BenchmarkColumnHeader {...props} benchmark={benchmark} valueFormatter={valueRenderFunctions.formatNumber} />
				),
				headerName: 'Opens',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatNumber,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: 'opensPercentage',
				renderHeader: (props) => (
					<BenchmarkColumnHeader {...props} benchmark={benchmark} valueFormatter={valueRenderFunctions.formatPercentage} />
				),
				headerName: 'Open rate',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatPercentage,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: 'clickCount',
				renderHeader: (props) => (
					<BenchmarkColumnHeader {...props} benchmark={benchmark} valueFormatter={valueRenderFunctions.formatNumber} />
				),
				headerName: 'Clicks',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatNumber,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: 'clicksPercentage',
				renderHeader: (props) => (
					<BenchmarkColumnHeader {...props} benchmark={benchmark} valueFormatter={valueRenderFunctions.formatPercentage} />
				),
				headerName: 'Click rate',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatPercentage,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: 'bounceCount',
				renderHeader: (props) => (
					<BenchmarkColumnHeader
						{...props}
						benchmark={benchmark}
						isIncreaseBad
						valueFormatter={valueRenderFunctions.formatNumber}
					/>
				),
				headerName: 'Bounces',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatNumber,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			},
			{
				field: 'bouncesPercentage',
				renderHeader: (props) => (
					<BenchmarkColumnHeader
						{...props}
						benchmark={benchmark}
						isIncreaseBad
						valueFormatter={valueRenderFunctions.formatPercentage}
					/>
				),
				headerName: 'Bounce rate',
				headerAlign: 'right',
				align: 'right',
				minWidth: 170,
				valueFormatter: cellValueRenderFunctions.formatPercentage,
				disableColumnMenu: true,
				sortingOrder: descendingFirstSortOptions
			}
		],
		[benchmark]
	);

	const getDetailPanelHeight = useCallback(() => 'auto', []);

	const getDetailPanelContent = useCallback(
		({ row }) =>
			row.deliveryCount > 0 ? <RecipientActivityTable recipient={row.emailTo} recipientId={row.emailToRecipientId} /> : null,
		[]
	);

	const handleSortModelChange = useCallback(
		(newSortModel) => {
			if (loadingState !== loadingStatesLookup.idle) {
				return;
			}
			dispatch(updateSortModel(newSortModel));
		},
		[dispatch, loadingState]
	);

	const handlePaginationModelChange = useCallback(
		(newPaginationModel) => {
			if (loadingState !== loadingStatesLookup.idle) {
				return;
			}
			dispatch(updatePaginationModel(newPaginationModel));
			setUserStorage((prev) => ({
				...prev,
				reportsActivity: { ...(prev.reportsActivity || {}), recipientsTablePageSize: newPaginationModel?.pageSize }
			}));
		},
		[dispatch, loadingState]
	);

	if (reportsActivityState.isEmpty) {
		return null;
	}

	return (
		<div className="recipients-activities-table" ref={ref}>
			{loadingState === loadingStatesLookup.full ? (
				<FullDataGridLoader />
			) : (
				<MuiDataGridPro
					className="activity-data-grid"
					experimentalFeatures={{ columnGrouping: true }}
					columnGroupingModel={columnGroupingModel}
					rows={items}
					columns={columns}
					loading={loadingState === loadingStatesLookup.partial}
					disableRowSelectionOnClick
					disableColumnFilter
					getRowId={(row) => row.emailTo}
					getDetailPanelHeight={getDetailPanelHeight}
					getDetailPanelContent={getDetailPanelContent}
					onSortModelChange={handleSortModelChange}
					sortModel={sortModel}
					slots={{
						loadingOverlay: PartialDataGridLoader
					}}
					rowCount={totalCount}
					pagination
					pageSizeOptions={pageSizeOptions}
					paginationModel={paginationModel}
					paginationMode="server"
					onPaginationModelChange={handlePaginationModelChange}
					columnHeaderHeight={90}
					disableColumnResize
					initialState={{
						pinnedColumns: { left: [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field, 'emailTo'] }
					}}
				/>
			)}
		</div>
	);
};

export default RecipientActivitiesTable;
