import capitalize from 'lodash.capitalize';
import moment from 'moment';
import { useMemo } from 'react';
import { validationResults } from '@truescope-web/react/lib/utils/validation';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { getEnvironmentName } from '@truescope-web/utils/lib/constants';
import { dateOptionsLookup } from '@truescope-web/utils/lib/dates';
import { extractedEntityTypes } from '@truescope-web/utils/lib/entityHelpers';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { isValidJson, joinDefined, stringIsNullOrEmpty } from '@truescope-web/utils/lib/strings';
import timezoneNames from '../timezone_names.json';
import { extractError } from './Api';

/**
 * date format
 */
export const dateFormat = `YYYY-MM-DD`;
export const dateTimeFormat = 'D MMMM YYYY h:mma';

/**
 * get date time in GMT from UTC format
 */
export const getLocalDateTime = (dateTimeInUTC, format) => {
	return moment
		.utc(dateTimeInUTC)
		.local()
		.format(format || dateTimeFormat);
};

/**
 * different types of data triggers
 */
export const searchDataTriggers = {
	none: 0,
	quickSearch: 1
};

/**
 * lookup for extracted entity fields on the filter schema
 */
export const extractedEntityFilterPathLookup = {
	[extractedEntityTypes.person]: 'people',
	[extractedEntityTypes.company]: 'companies',
	[extractedEntityTypes.location]: 'locations',
	[extractedEntityTypes.event]: 'events',
	[extractedEntityTypes.workOfArt]: 'worksOfArt',
	[extractedEntityTypes.consumerGood]: 'consumerGoods'
};

/**
 * formats a user's name to include their nickname
 * @param {*} user
 */
export const formatUserName = ({ nickname, name }) => {
	if (name.toLowerCase() === nickname.toLowerCase()) {
		return name;
	}
	return `${name} (${nickname})`;
};

/**
 * regex patterns
 */
export const regexPatterns = {
	wordsAndQuotedWords: /\w+|"[^"]+"/gim,
	chinese: /\p{sc=Han}+/gmu
};

/**
 * extracts words and quoted words and supports multiple languages
 * note: an unfortunate side effect is that languages will appear in a certain order
 * so "[lang 2] [lang 1] [lang 2]"" will become "[lang 1] [lang 2] [lang 2]". Not sure
 * if there's a nicer way to address this
 */
export const extractWordsAndQuotedWords = (input) => {
	//if this was hooked up to a chip text field, it may have been
	if (Array.isArray(input)) {
		return arrayIsNullOrEmpty(input) ? [] : input.map((text) => extractWordsAndQuotedWords(text).join(','));
	}

	if (stringIsNullOrEmpty(input)) {
		return [];
	}

	const matches = input.match(regexPatterns.wordsAndQuotedWords) || [];

	const chinese = input.match(regexPatterns.chinese) || [];

	return matches.concat(chinese);
};

/**
 * converts inputs to a "quoted" string
 * @param {*} input
 * @returns
 */
export const createExactMatchString = (input, delimiter) => {
	//if this was hooked up to a chip text field, it may have been
	if (Array.isArray(input)) {
		return arrayIsNullOrEmpty(input)
			? ''
			: joinDefined(
					input.map((text) => createExactMatchString(text, delimiter)),
					delimiter
			  );
	}

	if (stringIsNullOrEmpty(input)) {
		return '';
	}

	return `"${input.replace(/"/g, '')}"`;
};

/**
 * gets the current browser's timezone as an offset string. ie
 * +10:00
 * -05:00
 */
export const getTimeZoneOffset = () => moment().format('Z');

/**
 * gets the current timezone name
 */
export const getTimeZoneName = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * checks if the given parameter is an option
 */
export const isOption = (obj) => {
	if (typeof obj !== 'object') {
		return false;
	}
	return obj.hasOwnProperty('label') && obj.hasOwnProperty('value');
};

// Check if HTTPS is enabled in the window url
export const isHttpsEnabled = () => window.location.protocol === 'https:';

/**
 * gets the computed style of an element
 * @param {*} node
 * @param {*} prop
 */
export const getStyle = (node, prop) => {
	if (stringIsNullOrEmpty(node?.tagName) || node?.tagName === 'BODY') {
		return null;
	}
	return getComputedStyle(node, null).getPropertyValue(prop);
};

/**
 * department options
 */
export const departmentOptions = [
	{
		label: 'PR/Communications/Media',
		value: 'PR and Communications'
	},
	{
		label: 'Client Services/Account Management',
		value: 'Client Services/Account Management'
	},
	{
		label: 'Marketing',
		value: 'Marketing'
	},
	{
		label: 'Sales',
		value: 'Sales'
	},
	{
		label: 'Leadership',
		value: 'Leadership'
	},
	{
		label: 'HR',
		value: 'HR'
	},
	{
		label: 'Other',
		value: 'Other'
	}
];

/**
 * shows the first or default item in the array
 * @param {*} array
 * @param {*} defaultValue
 */
export const firstOrDefault = (array, defaultValue = null) => {
	if (isNullOrUndefined(array)) {
		return defaultValue;
	}

	if (!Array.isArray(array)) {
		return array;
	}

	return array.length > 0 ? array[0] : defaultValue;
};

/**
 * creates a lookup by id
 * @param {*} items
 * @param {*} property
 */
export const createIdLookup = (items, property) => {
	if (arrayIsNullOrEmpty(items)) {
		return {};
	}

	return items.reduce((acc, cur) => {
		acc[cur[property]] = true;
		return acc;
	}, {});
};

/**
 * lookup for schedule interval options
 */
export const scheduleIntervalOptionsLookup = {
	hourly: 1,
	daily: 2,
	weekly: 3,
	monthly: 4,
	yearly: 5
};

/**
 * schedule interval options
 * (note: we don't include yearly, because we don't generally send things by year!)
 */
export const scheduleIntervalOptions = [
	{ label: 'Hourly', value: scheduleIntervalOptionsLookup.hourly },
	{ label: 'Daily', value: scheduleIntervalOptionsLookup.daily },
	{ label: 'Weekly', value: scheduleIntervalOptionsLookup.weekly },
	{ label: 'Monthly', value: scheduleIntervalOptionsLookup.monthly }
];

/**
 * creates 2 validation rules for date picking
 * @param {string} dateType 'item' or 'publication'
 * @param {object} filters 'search_filter' or 'filters'
 */
export const validateDateFilters = (dateType, filters) => {
	const dateOption = filters?.[`${dateType}_date_option`];
	const dateFrom = filters?.[`${dateType}_date_from`];
	const dateTo = filters?.[`${dateType}_date_to`];

	if (isNullOrUndefined(dateOption)) {
		return validationResults.required(`A valid ${dateType} date must be selected`);
	}

	if (dateOption !== dateOptionsLookup.custom) {
		return validationResults.ok();
	}

	if (!moment(dateFrom).isValid()) {
		return validationResults.required(`invalid from date`);
	}

	if (!moment(dateTo).isValid()) {
		return validationResults.required(`invalid to date`);
	}

	return moment(dateTo).isAfter(dateFrom) ? validationResults.ok() : validationResults.required(`from date must be after to date`);
};

/**
 * validates the date option
 * @param {string} dateType 'item' or 'publication'
 * @param {object} filters
 * @returns validation result
 */
export const validateDateOption = (dateType, filters) => {
	const dateOption = filters?.[`${dateType}_date_option`];
	return isNullOrUndefined(dateOption) ? validationResults.required(`A valid ${dateType} date must be selected`) : validationResults.ok();
};

/**
 * validates the date from
 * @param {string} dateType 'item' or 'publication'
 * @param {object} filters
 * @returns validation result
 */
export const validateDateFrom = (dateType, filters) => {
	const dateOption = filters?.[`${dateType}_date_option`];
	const dateFrom = filters?.[`${dateType}_date_from`];
	const dateTo = filters?.[`${dateType}_date_to`];

	if (isNullOrUndefined(dateOption) || dateOption !== dateOptionsLookup.custom) {
		return validationResults.ok();
	}

	if (!moment(dateFrom).isValid()) {
		return validationResults.required(`invalid from date`);
	}

	return moment(dateTo).isAfter(dateFrom) ? validationResults.ok() : validationResults.required(`from date must be after to date`);
};

/**
 * validates the date to
 * @param {string} dateType 'item' or 'publication'
 * @param {object} filters
 * @returns validation result
 */
export const validateDateTo = (dateType, filters) => {
	const dateOption = filters?.[`${dateType}_date_option`];
	const dateFrom = filters?.[`${dateType}_date_from`];
	const dateTo = filters?.[`${dateType}_date_to`];

	if (isNullOrUndefined(dateOption) || dateOption !== dateOptionsLookup.custom) {
		return validationResults.ok();
	}

	if (!moment(dateTo).isValid()) {
		return validationResults.required(`invalid to date`);
	}

	return moment(dateTo).isAfter(dateFrom) ? validationResults.ok() : validationResults.required(`from date must be after to date`);
};

/**
 * custom breakpoints
 */
export const customBreakpoints = {
	inboxMd: 1200,
	inboxLg: 1600
};

/**
 * image alignment
 */
export const imageAlignments = {
	left: 'left',
	right: 'right',
	full: 'full',
	none: 'none'
};

/**
 * image alignment options
 */
export const imageAlignmentOptions = Object.values(imageAlignments).map((value) => ({ label: capitalize(value), value: value }));

/**
 * reorders a list
 * @param {*} list
 * @param {*} startIndex
 * @param {*} endIndex
 */
export const reorder = (list, startIndex, endIndex) => {
	const result = [...list];
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

/**
 * Retrieves a MediaPlayer Link for media content
 * @param {*} getClientApi - the API
 * @param {*} stationId - the unique channel identifier
 * @param {*} startDateTime - the start Time of the clip
 */
export const getMediaPlayerLink = (
	getClientApi,
	itemId,
	stationId,
	startDateTime,
	playStartDateTime,
	keywordsToHighlight,
	startTimeOffset = 0,
	playStartRegex,
	playStartRegexPreroll,
	workspaceId
) => {
	return getClientApi().then((api) =>
		api
			.post(`media-items/v1/${workspaceId}/items/${itemId}/viewer`, {
				stationId,
				startDateTime,
				playStartDateTime,
				keywordsToHighlight,
				startTimeOffset,
				playStartRegex,
				playStartRegexPreroll
			})
			.then(({ data, message }) => {
				return {
					data,
					message
				};
			})
			.catch((e) => ({ message: extractError(e) }))
	);
};

export const getPodcastMediaPlayerLink = async (getClientApi, itemId, podcastId, keywordsToHighlight, highlightIndex, workspaceId) => {
	try {
		const api = await getClientApi();
		return await api.post(`media-items/v1/${workspaceId}/items/${itemId}/viewer`, {
			podcastId,
			keywordsToHighlight,
			highlightIndex
		});
	} catch (e) {
		throw new Error(extractError(e));
	}
};

/**
 * creates options and allows them to be grouped
 * @param {*} items
 * @param {*} labelProperty
 * @param {*} valueProperty
 * @param {*} groupProperty
 * @returns
 */
export const createOptions = (items, labelProperty, valueProperty, groupProperty = null) => {
	if (arrayIsNullOrEmpty(items)) {
		return [];
	}

	if (stringIsNullOrEmpty(groupProperty)) {
		return items.reduce((o, item) => {
			o.push({
				label: item[labelProperty],
				value: item[valueProperty],
				metadata: item
			});
			return o;
		}, []);
	}

	const lookup = items.reduce((lookup, item) => {
		const option = {
			label: item[labelProperty],
			value: item[valueProperty],
			metadata: item
		};

		if (arrayIsNullOrEmpty(lookup[item[groupProperty]])) {
			lookup[item[groupProperty]] = [option];
		} else {
			lookup[item[groupProperty]].push(option);
		}

		return lookup;
	}, {});

	return Object.keys(lookup).reduce((options, key) => {
		options.push({
			label: key,
			options: lookup[key]
		});
		return options;
	}, []);
};

/**
 * takes a user's text-based search value and trims out 'foo AND bar' to 'foo bar'
 * @param {*} value
 * @returns
 */
export const sanitizeTextFilter = (value) => {
	return stringIsNullOrEmpty(value) ? null : value.replace(/\s+(AND|OR|NOT)\s+/, ' ');
};

/**
 * gets the mouse's position relative to an element (closest to the left edge or right edge)
 * @param {*} event
 * @param {*} element
 * @param {*} param2
 * @returns
 */
export const getRelativeMousePosition = (event, element, { left, right }) => {
	const rect = element.getBoundingClientRect();
	const percent = (event.clientX - rect.left) / rect.width;
	return percent < 0.5 ? left : right;
};

/**
 * gets the current index of an element in a DOM collection
 */
export const getElementIndex = (domElements, element) => {
	for (let x = 0; x < domElements.length; x++) {
		if (domElements[x] === element) {
			return x;
		}
	}

	return -1;
};

/**
 * creates a shallow copy of an array, finds the item by index and updates the item
 * @param {*} sourceArray
 * @param {*} findIndexFunc
 * @param {*} updatedItem
 * @returns
 */
export const updateArrayItem = (sourceArray, findIndexFunc, updatedItem) => {
	const shallow = [...sourceArray];
	const index = shallow.findIndex(findIndexFunc);
	if (index < 0) {
		console.error(`cannot find item. No updates were made`);
		return sourceArray;
	}
	Object.assign(shallow[index], updatedItem);
	return shallow;
};

/**
 * scrolls to top of view
 */
export const scrollToTop = (ref) => {
	ref?.current?.scrollIntoView({ behaviour: 'smooth', block: 'start', inline: 'nearest' });
};

/**
 * environment url
 */
export const environmentUrl = getEnvironmentName() === 'prod' ? 'app.truescope.com' : 'app.dev2.truescope.cloud';

/**
 * get react asset image url
 */
export const getAssetImageUrl = (fileName) => `https://${environmentUrl}/assets/${fileName}`;

/**
 * gets the locale of the browser
 * @returns en-au
 */
export const getLocale = () =>
	(Array.isArray(navigator.language) && navigator.language.length > 0 ? navigator.languages[0] : navigator.language) || 'en-US';

/**
 * adds a timestamp to an image url in order to cache bust it
 * @param {*} imageUrl
 */
export const cacheBustImage = (imageUrl) =>
	stringIsNullOrEmpty(imageUrl) ? '' : `${imageUrl.replace(/(\?ts=[\d]+)/g, '')}?ts=${new Date().getTime()}`;

/**
 *
 * @param {string} defaultClassName
 * @param {string} customClassName
 * @param {string} suffixParam
 */
export const concatClasses = (defaultClassName, customClassName = '', suffixParam = '') => {
	const suffix = !stringIsNullOrEmpty(suffixParam) ? `__${suffixParam}` : '';
	return `${defaultClassName + suffix} ${!stringIsNullOrEmpty(customClassName) ? `${customClassName + suffix}` : ''}`;
};

export const timezoneOptions = timezoneNames.map((option) => ({ label: option, value: option }));

/**
 * max date
 * note: this could be risky. if the user has the app open for more than 1 day, this might cause problems.
 */
export const getMaxDate = () => moment().format('YYYY-MM-DD');

/**
 * escapes an input so it can be used as a regular expression
 * @param {string} input user input
 * @returns {string} escaped user input
 */
export const escapeRegExp = (input) => input?.trim()?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

/**
 * checks if the auth0User has a given role assigned to their account
 * @param {object} auth0User auth0User (NOT THE DATABASE USER)
 * @param {string} role role name
 * @returns {boolean} true if the user has the given role
 */
export const userHasRole = (auth0User, role) => auth0User?.['https://truescope.com/roles']?.includes(role);

/**
 * key for test settings
 */
export const testSettingsKey = 'testSettings';

/**
 * key for debug settings
 */
export const debugSettingsKey = 'debugSettings';

/**
 * gets settings used during E2E testing
 * @returns test settings
 */
export const useTestSettings = () => {
	const testSettingsJson = localStorage.getItem(testSettingsKey);
	const testSettings = useMemo(() => (isValidJson(testSettingsJson) ? JSON.parse(testSettingsJson) : undefined), [testSettingsJson]);
	return testSettings;
};

/**
 * settings used when in debug mode
 * @returns debug settings
 */
export const useDebugSettings = () => {
	const debugSettingsJson = localStorage.getItem(debugSettingsKey);
	const debugSettings = useMemo(() => (isValidJson(debugSettingsJson) ? JSON.parse(debugSettingsJson) : undefined), [debugSettingsJson]);
	return debugSettings;
};

export const getIndefiniteArticle = (noun = '') => {
	if (stringIsNullOrEmpty(noun)) {
		return '';
	}
	return /^[aeiou].*/i.test(noun) ? 'an' : 'a';
};
