import { useAuth0 } from '@auth0/auth0-react';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { createApi } from '@truescope-web/utils/src/api';
import config from '../../config.json';
import { environmentUrl } from '../Constants';

const ApiLookupContext = createContext(undefined);

/**
 * alternative way to use the api, without composing the component
 * @returns
 */
export const useApiLookup = (optional = false) => {
	const context = useContext(ApiLookupContext);
	if (!optional && isNullOrUndefined(context)) {
		throw new Error('ApiLookupContext must be used within a ApiLookupProvider');
	}
	return context;
};

/**
 * contains the states for the api lookup
 * @param {*} param0
 */
export const ApiLookupProvider = ({ children }) => {
	const { isLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
	const [impersonate, setImpersonate] = useState(undefined);

	const getClientApi = useCallback(async () => {
		const { baseUrl, audience } = config.api.client;
		try {
			if (isLoading || !isAuthenticated) {
				return createApi(baseUrl);
			}

			const { access_token: accessToken } = await getAccessTokenSilently({
				detailedResponse: true,
				authorizationParams: {
					audience,
					redirect_uri: `https://${environmentUrl}`
				}
			});

			return createApi(baseUrl, { token: accessToken, impersonate });
		} catch (e) {
			if (e.error === 'missing_refresh_token' || e.error === 'invalid_grant') {
				loginWithRedirect();
			}
			console.warn(`failed to create api - ${e.message}`);
			return createApi(baseUrl);
		}
	}, [isLoading, isAuthenticated, impersonate]);

	return <ApiLookupContext.Provider value={[getClientApi, impersonate, setImpersonate]}>{children}</ApiLookupContext.Provider>;
};
