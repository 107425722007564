import React from 'react';
import {
	chartComparisonTypeIds,
	chartDefinitionIds,
	chartIntervals,
	chartMetricIds,
	chartTypeIds,
	widgetIds
} from '@truescope-web/react/lib/components/charts/enums';
import { validationResults } from '@truescope-web/react/lib/utils/validation';
import { arrayIsNullOrEmpty } from '@truescope-web/utils/lib/arrays';
import { dateOptionsLookup } from '@truescope-web/utils/lib/dates';
import { mediaTypesLookup } from '@truescope-web/utils/lib/mediaTypes';
import { isNullOrUndefined } from '@truescope-web/utils/lib/objects';
import { validateDateFrom, validateDateOption, validateDateTo } from '../../../components/Constants';
import { filterDefinitions } from '../../../components/widgets/FilterComponents';
import { assignIdsToCharts, createChart, createMultiChart } from '../../../components/widgets/charts/builder/ChartBuilderConstants';
import { dashboardGridColumns, dashboardGridMinimumWidths } from '../constants';
import { createNewDashboardTemplate, getChartMapFieldsByMarket } from './DashboardBuilderConstants';
import DateStep from './DateStep';
import FiltersStep from './FiltersStep';

const createSingleTargetCharts = ({ defaultInterval, sharedChartProps }, dashboardDataContext, workspace) => {
	return [
		createMultiChart(
			{
				//id 1
				chartRequests: [
					{
						chart_definition_id: chartDefinitionIds.totalVolume,
						chart_type_id: chartTypeIds.card,
						chart_metric_ids: [chartMetricIds.volume],
						benchmark_enabled: true
					},
					{
						chart_definition_id: chartDefinitionIds.socialEngagement,
						chart_type_id: chartTypeIds.card,
						chart_metric_ids: [chartMetricIds.socialEngagement],
						benchmark_enabled: true,
						chart_name: 'Social engagement'
					}
				],
				layout: {
					md: { w: 4, h: 1, x: 0, y: 0 },
					xs: { w: 1, h: 2, x: 0, y: 0 }
				},
				search_filter: {},
				widget_id: widgetIds.metrics,
				chart_definition_id: chartDefinitionIds.scoreCards,
				...sharedChartProps
			},
			dashboardDataContext
		),
		createChart(
			{
				//id 4
				chart_name: 'When was coverage trending?',
				widget_id: widgetIds.timeSeries,
				chart_definition_id: chartDefinitionIds.volumeAndAudienceOverTime,
				chart_type_id: chartTypeIds.timeSeriesLine,
				chart_metric_ids: [chartMetricIds.volume, chartMetricIds.socialEngagement, chartMetricIds.potentialImpressions],
				chart_interval_id: defaultInterval.chart_interval_id,
				search_filter: {},
				layout: {
					md: { w: 4, h: 3, x: 0, y: 1 },
					xs: { w: 1, h: 3, x: 0, y: 2 }
				},
				...sharedChartProps
			},
			dashboardDataContext
		),
		createChart(
			{
				//id 5
				chart_name: 'Which media items have the most engagement?',
				widget_id: widgetIds.content,
				chart_definition_id: chartDefinitionIds.mediaItemList,
				chart_type_id: chartTypeIds.table,
				chart_metric_ids: [chartMetricIds.volume],
				chart_interval_id: defaultInterval.chart_interval_id,
				chart_map_definition_id: 1,
				search_filter: {
					sort: 'social_engagement.totals'
				},
				layout: {
					md: { w: 2, h: 7, x: 4, y: 0 },
					xs: { w: 1, h: 3, x: 0, y: 5 }
				},
				mediaItemListDisplaySettings: {
					showAudience: true,
					showImage: true,
					showSocialEngagement: true,
					showAve: workspace.show_ave,
					...(workspace.show_ave
						? { currencyCode: workspace.exchangeRate.currencyCode, currencySymbol: workspace.exchangeRate.currencySymbol }
						: {})
				},
				...sharedChartProps
			},
			dashboardDataContext
		),
		createChart(
			{
				//id 6
				widget_id: widgetIds.geography,
				chart_definition_id: chartDefinitionIds.bubbleMap,
				chart_type_id: chartTypeIds.bubbleMap,
				chart_metric_ids: [chartMetricIds.volume],
				chart_interval_id: defaultInterval.chart_interval_id,
				search_filter: {},
				layout: {
					md: { w: 4, h: 3, x: 0, y: 4 },
					xs: { w: 1, h: 3, x: 0, y: 8 }
				},
				...getChartMapFieldsByMarket(
					workspace,
					{ chart_metric_ids: [chartMetricIds.volume], chart_definition_name: 'Bubble Map' },
					dashboardDataContext
				),
				...sharedChartProps
			},
			dashboardDataContext
		),
		createChart(
			{
				//id 7
				chart_name: 'Which Hashtags are being used?',
				compare_field: 'hashtags',
				widget_id: widgetIds.wordCloud,
				chart_definition_id: chartDefinitionIds.hashtags,
				chart_type_id: chartTypeIds.wordCloud,
				chart_metric_ids: [chartMetricIds.volume],
				search_filter: {
					media_types: [
						mediaTypesLookup.facebook,
						mediaTypesLookup.instagram,
						mediaTypesLookup.reddit,
						mediaTypesLookup.tiktok,
						mediaTypesLookup.linkedIn,
						mediaTypesLookup.youtube
					]
				},
				layout: {
					md: { w: 2, h: 3, x: 0, y: 7 },
					xs: { w: 1, h: 3, x: 0, y: 11 }
				},
				...sharedChartProps
			},
			dashboardDataContext
		),
		createChart(
			{
				//id 8
				chart_name: 'Which emojis are being used?',
				compare_field: 'emoji',
				widget_id: widgetIds.wordCloud,
				chart_definition_id: chartDefinitionIds.emoji,
				chart_type_id: chartTypeIds.wordCloudWithoutRotation,
				chart_metric_ids: [chartMetricIds.volume],
				search_filter: {
					media_types: [
						mediaTypesLookup.facebook,
						mediaTypesLookup.instagram,
						mediaTypesLookup.twitter,
						mediaTypesLookup.reddit,
						mediaTypesLookup.tiktok,
						mediaTypesLookup.linkedIn,
						mediaTypesLookup.youtube
					]
				},
				layout: {
					md: { w: 2, h: 3, x: 2, y: 7 },
					xs: { w: 1, h: 3, x: 0, y: 14 }
				},
				...sharedChartProps
			},
			dashboardDataContext
		),
		createChart(
			{
				//id: 9
				chart_name: 'What is being talked about?',
				compare_field: 'key_phrases',
				widget_id: widgetIds.wordCloud,
				chart_definition_id: chartDefinitionIds.keyPhrases,
				chart_type_id: chartTypeIds.withoutSentiment,
				chart_metric_ids: [chartMetricIds.volume],
				search_filter: {},
				layout: {
					md: { w: 2, h: 3, x: 4, y: 7 },
					xs: { w: 1, h: 3, x: 0, y: 17 }
				},
				...sharedChartProps
			},
			dashboardDataContext
		),
		createChart(
			{
				//id 10
				chart_name: 'Media types breakdown',
				widget_id: widgetIds.mediaTypes,
				chart_definition_id: chartDefinitionIds.mediaTypesBreakdown,
				chart_type_id: chartTypeIds.pie,
				chart_metric_ids: [chartMetricIds.volume],
				search_filter: {},
				layout: {
					md: { w: 2, h: 3, x: 0, y: 10 },
					xs: { w: 1, h: 3, x: 0, y: 20 }
				},
				...sharedChartProps
			},
			dashboardDataContext
		),
		createChart(
			{
				//id 11
				chart_name: 'Which media types are trending?',
				widget_id: widgetIds.mediaTypes,
				chart_definition_id: chartDefinitionIds.mediaTypesOverTime,
				chart_type_id: chartTypeIds.stacked,
				chart_metric_ids: [chartMetricIds.volume],
				search_filter: {},
				layout: {
					md: { w: 4, h: 3, x: 2, y: 13 },
					xs: { w: 1, h: 3, x: 0, y: 23 }
				},
				...sharedChartProps
			},
			dashboardDataContext
		),
		createChart(
			{
				//id 12
				chart_name: 'Which channels are covering the most?',
				compare_field: filterDefinitions.sources.field,
				chart_comparison_id: chartComparisonTypeIds.top10,
				widget_id: widgetIds.sources,
				chart_definition_id: chartDefinitionIds.sourcesBreakdown,
				chart_type_id: chartTypeIds.bar,
				chart_metric_ids: [chartMetricIds.volume],
				search_filter: {},
				layout: {
					md: { w: 3, h: 3, x: 0, y: 16 },
					xs: { w: 1, h: 3, x: 0, y: 26 }
				},
				...sharedChartProps
			},
			dashboardDataContext
		),
		createChart(
			{
				//id 13
				chart_name: 'Which authors are gaining the most social engagement?',
				compare_field: filterDefinitions.authors.field,
				chart_comparison_id: chartComparisonTypeIds.top10,
				widget_id: widgetIds.authors,
				chart_definition_id: chartDefinitionIds.authorsBreakdown,
				chart_type_id: chartTypeIds.bar,
				chart_metric_ids: [chartMetricIds.socialEngagement],
				search_filter: {},
				layout: {
					md: { w: 3, h: 3, x: 3, y: 16 },
					xs: { w: 1, h: 3, x: 0, y: 29 }
				},
				...sharedChartProps
			},
			dashboardDataContext
		),
		createChart(
			{
				//id 14
				chart_name: 'Which orgs are most frequently mentioned?',
				compare_field: filterDefinitions.companies.field,
				chart_comparison_id: chartComparisonTypeIds.top10,
				widget_id: widgetIds.companies,
				chart_definition_id: chartDefinitionIds.companiesShareOfVoice,
				chart_type_id: chartTypeIds.table,
				chart_metric_ids: [chartMetricIds.volume],
				search_filter: {},
				layout: {
					md: { w: 2, h: 3, x: 0, y: 19 },
					xs: { w: 1, h: 3, x: 0, y: 32 }
				},
				...sharedChartProps
			},
			dashboardDataContext
		),

		createChart(
			{
				//id 15
				chart_name: 'Which people are most frequently mentioned?',
				compare_field: filterDefinitions.people.field,
				chart_comparison_id: chartComparisonTypeIds.top10,
				widget_id: widgetIds.people,
				chart_definition_id: chartDefinitionIds.peopleShareOfVoice,
				chart_type_id: chartTypeIds.table,
				chart_metric_ids: [chartMetricIds.volume],
				search_filter: {},
				layout: {
					md: { w: 2, h: 3, x: 2, y: 19 },
					xs: { w: 1, h: 3, x: 0, y: 35 }
				},
				...sharedChartProps
			},
			dashboardDataContext
		),
		createChart(
			{
				//id 16
				chart_name: 'Which locations are most frequently mentioned?',
				compare_field: filterDefinitions.locations.field,
				chart_comparison_id: chartComparisonTypeIds.top10,
				widget_id: widgetIds.locations,
				chart_definition_id: chartDefinitionIds.locationsWithinCoverageBreakdown,
				chart_type_id: chartTypeIds.table,
				chart_metric_ids: [chartMetricIds.volume],
				search_filter: {},
				layout: {
					md: { w: 2, h: 3, x: 4, y: 19 },
					xs: { w: 1, h: 3, x: 0, y: 38 }
				},
				...sharedChartProps
			},
			dashboardDataContext
		)
	].filter((chart) => !isNullOrUndefined(chart));
};

const createMultiTargetCharts = ({ defaultInterval, defaultFilters, sharedChartProps }, dashboardDataContext, workspace) => {
	const charts = createSingleTargetCharts({ defaultInterval, sharedChartProps }, dashboardDataContext, workspace);
	let breakdownProps, overTimeProps, sharedComparisonProps;

	if (!arrayIsNullOrEmpty(defaultFilters.query_ids)) {
		sharedComparisonProps = {
			compare_field: filterDefinitions.query_ids.field,
			compare_selected_value_ids: defaultFilters.query_ids,
			compare_selected_values: workspace.queries
				.filter((query) => defaultFilters.query_ids.includes(query.query_id))
				.map((query) => ({ label: query.name, value: query.query_id }))
		};
		breakdownProps = {
			widget_id: widgetIds.queries,
			chart_definition_id: chartDefinitionIds.queriesBreakdown
		};
		overTimeProps = {
			widget_id: widgetIds.queries,
			chart_definition_id: chartDefinitionIds.queriesOverTime,
			chart_comparison_id: chartComparisonTypeIds.filterTerms10
		};
	} else {
		console.error('default filters has queries. cannot create chart');
		return null;
	}

	const breakDownchart = createChart(
		{
			//id 18
			...breakdownProps,
			...sharedComparisonProps,
			chart_name: 'Query breakdown',
			chart_type_id: chartTypeIds.pie,
			chart_metric_ids: [chartMetricIds.volume],
			search_filter: {},
			...sharedChartProps
		},
		dashboardDataContext
	);

	if (isNullOrUndefined(breakDownchart)) {
		return null;
	}

	charts.splice(3, 0, breakDownchart);

	const overTimeChart = createChart(
		{
			//id 17
			...overTimeProps,
			...sharedComparisonProps,
			chart_name: 'Query over time',
			chart_type_id: chartTypeIds.timeSeriesLine,
			chart_metric_ids: [chartMetricIds.volume],
			chart_interval_id: defaultInterval.chart_interval_id,
			search_filter: {},
			...sharedChartProps
		},
		dashboardDataContext
	);

	if (isNullOrUndefined(overTimeChart)) {
		return null;
	}

	charts.splice(4, 0, overTimeChart);

	// Multichart, coverage trending, bubble map, media list
	charts[0].layout = {
		//1
		md: { w: 4, h: 1, x: 0, y: 0 },
		xs: { w: 1, h: 2, x: 0, y: 0 }
	};
	charts[1].layout = {
		//4
		md: { w: 4, h: 3, x: 0, y: 1 },
		xs: { w: 1, h: 3, x: 0, y: 2 }
	};
	charts[2].layout = {
		//5
		md: { w: 2, h: 7, x: 4, y: 0 },
		xs: { w: 1, h: 4, x: 0, y: 5 }
	};
	charts[5].layout = {
		//6
		md: { w: 4, h: 3, x: 0, y: 4 },
		xs: { w: 1, h: 3, x: 0, y: 9 }
	};

	// Query breakdown, query over time
	charts[3].layout = {
		//7
		md: { w: 2, h: 3, x: 0, y: 7 },
		xs: { w: 1, h: 3, x: 0, y: 12 }
	};
	charts[4].layout = {
		//8
		md: { w: 4, h: 3, x: 2, y: 7 },
		xs: { w: 1, h: 3, x: 0, y: 15 }
	};

	// Hashtag, emoji, keyphrase
	charts[6].layout = {
		//9
		md: { w: 2, h: 3, x: 0, y: 10 },
		xs: { w: 1, h: 3, x: 0, y: 18 }
	};
	charts[7].layout = {
		//10
		md: { w: 2, h: 3, x: 2, y: 10 },
		xs: { w: 1, h: 3, x: 0, y: 21 }
	};
	charts[8].layout = {
		//11
		md: { w: 2, h: 3, x: 4, y: 10 },
		xs: { w: 1, h: 3, x: 0, y: 24 }
	};

	// Media type breakdown, trending media type
	charts[9].layout = {
		//12
		md: { w: 2, h: 3, x: 0, y: 13 },
		xs: { w: 1, h: 3, x: 0, y: 27 }
	};
	charts[10].layout = {
		//13
		md: { w: 4, h: 3, x: 2, y: 13 },
		xs: { w: 1, h: 3, x: 0, y: 30 }
	};

	// Channels, authors
	charts[11].layout = {
		//14
		md: { w: 3, h: 3, x: 0, y: 16 },
		xs: { w: 1, h: 3, x: 0, y: 33 }
	};
	charts[12].layout = {
		//15
		md: { w: 3, h: 3, x: 3, y: 16 },
		xs: { w: 1, h: 3, x: 0, y: 36 }
	};

	// Orgs, people, locations
	charts[13].layout = {
		//16
		md: { w: 2, h: 3, x: 0, y: 19 },
		xs: { w: 1, h: 3, x: 0, y: 39 }
	};
	charts[14].layout = {
		//17
		md: { w: 2, h: 3, x: 2, y: 19 },
		xs: { w: 1, h: 3, x: 0, y: 42 }
	};
	charts[15].layout = {
		//18
		md: { w: 2, h: 3, x: 4, y: 19 },
		xs: { w: 1, h: 3, x: 0, y: 45 }
	};

	return charts;
};

const MediaOverviewDashboardTemplate = {
	id: 2,
	icon: 'mediaOverview',
	name: 'Media Overview',
	onCreate: (dashboard, defaultInterval, defaultFiltersParam, dashboardDataContext, workspace) => {
		const sharedChartProps = {
			props: {},
			workspace_id: workspace.workspace_id
		};

		let defaultFilters = {
			...defaultFiltersParam,
			workspace_id: workspace.workspace_id
		};

		const isMulti = !arrayIsNullOrEmpty(defaultFilters.query_ids) && defaultFilters.query_ids.length > 1;

		dashboard.charts = assignIdsToCharts(
			isMulti
				? createMultiTargetCharts({ defaultInterval, defaultFilters, sharedChartProps }, dashboardDataContext, workspace)
				: createSingleTargetCharts({ defaultInterval, sharedChartProps }, dashboardDataContext, workspace)
		);
		dashboard.filters = defaultFilters;

		const createLayoutEntry = (chart, layoutKey) => {
			return {
				moved: false,
				static: false,
				h: chart.layout[layoutKey].h,
				w: chart.layout[layoutKey].w,
				i: `${chart.id}`,
				x: chart.layout[layoutKey].x,
				y: chart.layout[layoutKey].y,
				maxW: dashboardGridColumns[layoutKey],
				minW: Math.max(dashboardGridMinimumWidths[layoutKey], chart.block_width),
				minH: chart.block_height
			};
		};

		dashboard.layout = dashboard.charts.reduce(
			(layout, chart) => {
				layout.md.push(createLayoutEntry(chart, 'md'));
				layout.xs.push(createLayoutEntry(chart, 'xs'));
				delete chart.layout;
				return layout;
			},
			{
				md: [],
				xs: []
			}
		);
	},
	setDefaultProperties: ({ dashboardDataContext }) => {
		return {
			dashboard: createNewDashboardTemplate(),
			defaultFilters: {
				publication_date_option: dateOptionsLookup.last30Days
			},
			defaultInterval: dashboardDataContext.chartIntervalsLookup[chartIntervals.day]
		};
	},
	steps: [
		{
			step: {
				label: 'Filters',
				component: (props) => <FiltersStep {...props} />
			},
			validationRules: {
				query_ids: ({ defaultFilters }) => {
					return arrayIsNullOrEmpty(defaultFilters?.query_ids)
						? validationResults.required('Please select at least one query')
						: validationResults.ok();
				}
			}
		},
		{
			step: {
				label: 'Date',
				component: (props) => <DateStep {...props} />
			},
			validationRules: {
				publication_date_option: ({ defaultFilters }) => validateDateOption('publication', defaultFilters),
				publication_date_from: ({ defaultFilters }) => validateDateFrom('publication', defaultFilters),
				publication_date_to: ({ defaultFilters }) => validateDateTo('publication', defaultFilters)
			}
		}
	]
};

export default MediaOverviewDashboardTemplate;
